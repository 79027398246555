import { ChildrenRef } from '../../../../../lib/web/components/children-ref';
import { ClickListener } from '../../../../../lib/web/components/click-listener';
import { Component } from '../../../../../lib/web/components/component';
import { EventListener } from '../../../../../lib/web/components/event-listener';
import { ModalComponent } from '../../../../../lib/web/components/modal.component';
import { DictionaryObject } from '../../../../../lib/web/core/types';
import { ApiService } from '../../../services/api.service';
import { ValidationService } from '../../../services/validation.service';

@Component({
    selector: '.m-create-pin'
})
export class CreatePinComponent extends ModalComponent<HTMLInputElement> {

    private _oldParentalPin: string = null;

    @ChildrenRef(HTMLInputElement, '.a-input-pin__code')
    private _inputsElement: HTMLInputElement[];

    public constructor(node: HTMLInputElement,
        private _apiService: ApiService,
        private _validationService: ValidationService) {
        super(node);
    }

    public onInit(): void {
        super.onInit();
    }

    public async open(oldParentalPin?: string): Promise<any> {
        this.loadData();
        this._oldParentalPin = oldParentalPin;
        return super.open();
    }

    private async loadData(): Promise<void> {
        this.setFormData({ parentalPin: null });
        this.isLoadingData = true;
        this.disable('.m-create-pin__save');
        this.clearInputs();
        this.isLoadingData = false;
    }

    @EventListener('input', 'input')
    public onChange(): void {
        this.registerFormFieldInput('.m-create-pin__form', field => this.validateField(field));
        this.enable('.m-create-pin__save');
    }

    @ClickListener('.m-create-pin__save')
    public onSave(): void {
        if (this.validate()) {
            this.submit();
        }
    }

    private async submit(): Promise<void> {
        try {
            this.disable('.m-create-pin__save');
            const { parentalPin } = this.getFormData('.m-create-pin__form');
            let response = null;
            if (this._oldParentalPin) {
                response = await this._apiService.put('/api/v1/user/parental-pin', {
                    body: { parentalPin, oldParentalPin: this._oldParentalPin }
                });
            } else {
                response = await this._apiService.post('/api/v1/user/parental-pin', {
                    body: { parentalPin }
                });
            }
            this.close(response ? parentalPin : null);
        }
        catch (e: any) {
            if (e.statusCode == 400) {
                this._validationService.showErrors('.m-create-pin__form', JSON.parse(e.data?.message));
            }
            else {
                this.showUnexpectedError(e);
            }
        }
        finally {
            this.enable('.m-create-pin__save');
        }
    }

    public validate(): boolean {
        const data: any = this.getFormData('.m-create-pin__form');
        const errors: DictionaryObject<string[]> = this._validationService.validateParentalPin(data, data, 4);
        this._validationService.showErrors('.m-create-pin__form', errors);
        return !this._validationService.hasErrors(errors);
    }

    public validateField(field: any): void {
        const errors: DictionaryObject<string[]> = this._validationService.validateParentalPin(field, this.getFormData('.m-create-pin__form'), 4);
        this._validationService.showErrors('.m-create-pin__form', errors);
    }

    private clearInputs() {
        this._inputsElement.forEach(i => i.value = '');
    }
}