import { ChildRef } from '../../../../../lib/web/components/child-ref';
import { ClickListener } from '../../../../../lib/web/components/click-listener';
import { Component } from '../../../../../lib/web/components/component';
import { ComponentBase } from '../../../../../lib/web/components/component-base';
import { EventListener } from '../../../../../lib/web/components/event-listener';
import { Input } from '../../../../../lib/web/components/input';
import { DictionaryObject } from '../../../../../lib/web/core/types';
import { ApiService } from '../../../services/api.service';
import { SessionService } from '../../../services/session.service';
import { CountrySelectorComponent } from '../../atoms/country-selector/country-selector.component';
import { SwitchBoxComponent } from '../../atoms/switch-box/switch-box.component';
import { ChangePasswordComponent } from '../change-password/change-password.component';
import { ChangePostalCodeComponent } from '../change-postal-code/change-postal-code.component';
import { ChangeProfileComponent } from '../change-profile/change-profile.component';
import { CreatePinComponent } from '../create-pin/create-pin.component';
import { EnterPinComponent } from '../enter-pin/enter-pin.component';

@Component({
    selector: '.m-profile-data'
})
export class ProfileDataComponent extends ComponentBase<HTMLElement> {

    @Input('country')
    private _country: number = null;
    @Input('postalCode')
    private _postalCode: string = null;
    @ChildRef()
    private _changePassword: ChangePasswordComponent = null;
    @ChildRef()
    private _changeProfile: ChangeProfileComponent = null;
    @ChildRef()
    private _changePostalCode: ChangePostalCodeComponent = null;
    @ChildRef()
    private _countrySelector: CountrySelectorComponent = null;
    @ChildRef()
    private _createPin: CreatePinComponent = null;
    @ChildRef()
    private _enterPin: EnterPinComponent = null;

    public constructor(node: HTMLElement, private _sessionService: SessionService, private _apiService: ApiService) {
        super(node);
    }

    public onInit(mode?: 'load' | 'redirect'): void {
        super.onInit(mode);
        const paths: string[] = location.pathname.split('/');
        if (paths.last() == 'change-password') {
            this._changePassword.open();
        }
    }

    @ClickListener('.m-profile-data__discipline')
    public async onDisciplineClicked(e: MouseEvent): Promise<void> {
        const button: HTMLButtonElement = e.target as HTMLButtonElement;
        const disciplineId: number = parseInt(button.dataset.id);
        if (button.classList.contains('u-button--primary')) {
            this._apiService.delete(`/api/v1/user/discipline/${disciplineId}`);

        }
        else {
            this._apiService.post(`/api/v1/user/discipline/${disciplineId}`);
        }
        this.toggleClasses(button.classList.contains('u-button--secondary'), 'u-button--primary', 'u-button--secondary', button);
    }

    @ClickListener('#lnkSignOut')
    public async onSignOut(): Promise<void> {
        this.dispatchCustomWindowEvent('sign-out-request');
    }

    @ClickListener('#lnkSignOff')
    public async onSignOff(): Promise<void> {
        if (await this.showQuestion(USER_LOCALE.signOff, USER_LOCALE.signOffQuestion, 'ok', { result: 'cancel' }, { result: 'ok' }) == 'ok') {
            try {
                await this._apiService.delete('/api/v1/user');
                this._sessionService.remove();
                this.redirect('/');
            }
            catch (e: any) {
                if (e.statusCode == 409) {
                    this.showUnexpectedError({ message: e.data?.message });
                }
                else {
                    this.showUnexpectedError(e);
                }
            }
        }
    }

    @ClickListener('#btnChangePwd')
    public async onChangePassword(): Promise<void> {
        this._changePassword.open();
    }

    @ClickListener('#btnChangeProfile')
    public async onChangeProfile(): Promise<void> {
        const data: any = await this._changeProfile.open();
        if (data) {
            this.setProfileData(data);
        }
    }

    @ClickListener('#btnChangeCountry')
    public async onChangeCountry(): Promise<void> {
        const country: { id: number, name: string, flag: string, isDefault: boolean } = await this._countrySelector.open(this._country);
        if (country) {
            this.changeLocation(country, this._postalCode);
        }
    }

    @ClickListener('#btnCreatePin')
    public async onCreatePin(): Promise<void> {
        await this.createPin();
    }

    @ClickListener('#btnEditPin')
    public async onUpdatePin(): Promise<void> {
        const oldParentalPin: any = await this._enterPin.open();
        if (oldParentalPin) {
            await this.createPin(oldParentalPin);
        }
    }

    private async createPin(oldParentalPin?: string): Promise<void> {
        const parentalPin = await this._createPin.open(oldParentalPin);
        if (parentalPin) {
            this.setUserSetting('parental-control-with-pin', true, parentalPin);
            this.reload();
        }
    }

    private async changeLocation(country: { id: number, name: string }, postalCode: string): Promise<void> {
        try {
            await this._apiService.post(`/api/v1/user/setting/location/${country.id}${postalCode ? `/${postalCode}` : ''}`);
            this._country = country.id;
            this._postalCode = postalCode;
            this.setInnerHTML(country.name, '.m-profile-data__field-value--countryName');
            if (this._postalCode) {
                this._sessionService.setPostalCode(postalCode);
                this.setInnerHTML(this._postalCode, '.m-profile-data__field-value--postalCode');
                this.removeClass('u-hidden-important', '.m-profile-data__field--postalCode');
            }
            else {
                this.addClass('u-hidden-important', '.m-profile-data__field--postalCode');
            }
        }
        catch (e: any) {
            if (e.statusCode == 409) {
                if (e.data?.message == 'E-POSTAL-CODE-EMPTY') {
                    this.changeLocation(country, null);
                }
                else if (e.data?.message == 'E-POSTAL-CODE-REQUIRED') {
                    this.changePostalCode(country);
                }      
                else {                    
                    this.showUnexpectedError(e);
                }              
            }
            else if (e.statusCode == 400) {
                this.changePostalCode(country, e.data?.message ? JSON.parse(e.data.message): null);
            }
            else {
                this.showUnexpectedError(e);
            }
        }
    }

    @ClickListener('#btnChangePostalCode')
    public async onChangePostalCode(): Promise<void> {
        this.changePostalCode({ id: this._country, name: this.getInnerHTML('.m-profile-data__field-value--countryName') });
    }

    public async changePostalCode(country: { id: number, name: string}, errors?: DictionaryObject<string[]>): Promise<void> {
        const postalCode: string = await this._changePostalCode.open({ postalCode: this._postalCode, country: country.id, errors });
        if (postalCode) {
            this.changeLocation(country || { id: this._country, name: this.getInnerHTML('.m-profile-data__field-value--countryName') }, postalCode);       
        }
        else if (country.id != this._country) {
            this.showInfo(USER_LOCALE.operationCancelled, USER_LOCALE.dataNotUpdated);
        }
    }

    @ClickListener('.m-profile-data__locale')
    public async onChangeLanguage(e: MouseEvent): Promise<void> {
        const locale: string = (e.target as HTMLButtonElement).dataset.locale;
        try {
            await this._apiService.post(`/api/v1/user/setting/language/${locale}`);
            this._sessionService.setLanguage(locale);
            window.location.reload();
        }
        catch (e: any) {
            this.showUnexpectedError(e);
        }
    }

    @EventListener('change', '.a-switch-box')
    public async onChangeOption(e: CustomEvent): Promise<void> {
        const switchBox: SwitchBoxComponent = e.detail;
        if (switchBox) {
            const id: string = switchBox.node.querySelector('input').id;
            switch (id) {
                case 'sbAutoplay':
                    this.setUserSetting('autoplay', switchBox.value);
                    break;
                case 'sbParentControl':
                    if (switchBox.value) {
                        this.setUserSetting('parental-control', switchBox.value);
                    } else {
                        const parentalPin = await this._enterPin.open();
                        if (parentalPin) {
                            this.setUserSetting('parental-control-with-pin', switchBox.value, parentalPin);
                        }
                        else {
                            switchBox.value = !switchBox.value;
                        }
                    }
                    break;
            }
        }
    }

    private async setUserSetting(name: string, value: boolean, optionalValue?: string): Promise<void> {
        try {
            await this._apiService.post(`/api/v1/user/setting/${name}/${value}${!!optionalValue ? `/${optionalValue}`:``}`);
        }
        catch (e: any) {
            this.showUnexpectedError(e);
        }
    }

    private setProfileData(data: any): void {
        const { fullname, gender, birthDate } = data;
        this.setInnerHTML(fullname, '.m-profile-data__field-value--fullname');
        this.setInnerHTML(gender, '.m-profile-data__field-value--gender');
        this.setInnerHTML(birthDate, '.m-profile-data__field-value--birthDate');
        this.dispatchCustomEvent('profile-updated', data);
    }
}