import { ChildRef } from '../../../../../lib/web/components/child-ref';
import { Component } from '../../../../../lib/web/components/component';
import { ComponentBase } from '../../../../../lib/web/components/component-base';
import { EventListener } from '../../../../../lib/web/components/event-listener';
import { Input } from '../../../../../lib/web/components/input';
import { Key } from '../../../../../lib/web/core/key';

@Component({
    selector: '.a-input-pin'
})
export class InputPinComponent extends ComponentBase<HTMLInputElement> {

    private _inputElements: HTMLInputElement[] = null;

    @ChildRef('input')
    private _inputElement: HTMLInputElement = null;
    @Input('digits')
    private _digits: number = null;
    @ChildRef('.a-input-pin__codes')
    private _codesElement: HTMLDivElement;

    public constructor(node: HTMLInputElement) {
        super(node);
    }

    public onInit(): void {
        (this._node as any).name = this._inputElement.name;
        this.value = null;
        this.createInput(this.digits);
        this.focus();
        this._inputElements = Array.from(this._codesElement.children) as HTMLInputElement[];
    }

    @EventListener('blur', 'input')
    public onBlur(): void {
        this.dispatchCustomEvent('blur');
    }

    @EventListener('input', 'input')
    public onInput(): void {
        this.dispatchCustomEvent('input');
    }

    private createInput(digits: number) {
        for (let i = 0; i < digits; i++) {
            const id = `code${i}`;
            if (!this._codesElement.querySelector(`#${id}`)) {
                const el = document.createElement('input');
                el.id = `code${i}`
                el.type = "number";
                el.pattern = "[0-9]*";
                el.min = "0";
                el.max = "9";
                el.value = "";
                el.classList.add('a-input-pin__code');
                el.oninput = (ev) => this.onInputElement(ev);
                el.onkeydown = (ev) => this.onKeydownElement(ev);
                this._codesElement.appendChild(el);
            }
        }
    }

    public onInputElement(event: Event): void {
        const inputElement: HTMLInputElement = event.target as HTMLInputElement;
        const index: number = this._inputElements.indexOf(inputElement);
        if (inputElement.value.length === 1 && index + 1 < this._inputElements.length) {
            this._inputElements[index + 1].focus();
        }
        // Paste case
        if (inputElement.value.length > 1) {
            //If pasted is not a number
            if (isNaN(Number(inputElement.value))) {
                inputElement.value = '';
                this.updateInput();
                return;
            }
            // Fill all inputs with integers
            const chars: string[] = inputElement.value.split('');
            for (let pos: number = 0; pos < chars.length; pos++) {
                // If pasted is longer than inputs
                if (pos + index >= this._inputElements.length) {
                    break;
                }
                // Fill target input with value
                const targetInput: HTMLInputElement = this._inputElements[pos + index];
                targetInput.value = chars[pos];
            }
            // Focus last empty input
            let focusIndex: number = Math.min(this._inputElements.length - 1, index + chars.length);
            this._inputElements[focusIndex].focus();
        }
        this.updateInput();
    }

    public onKeydownElement(event: KeyboardEvent): void {
        const inputElement: HTMLInputElement = event.target as HTMLInputElement;
        const index: number = this._inputElements.indexOf(inputElement);
        if (event.key == 'Backspace' && inputElement.value == '' && index != 0) {
            for (let pos: number = index; pos < this._inputElements.length - 1; pos++) {
                this._inputElements[pos].value = this._inputElements[pos + 1].value;
            }
            this._inputElements[index - 1].value = '';
            this._inputElements[index - 1].focus();
            this.updateInput();
            return;
        };
        if (event.key == 'Delete' && index != this._inputElements.length - 1) {
            for (let pos: number = index; pos < this._inputElements.length - 1; pos++) {
                this._inputElements[pos].value = this._inputElements[pos + 1].value;
            }
            // clear the last box
            this._inputElements[this._inputElements.length - 1].value = '';
            inputElement.select();
            event.preventDefault();
            this.updateInput();
            return;
        };
        if (event.key == Key.ArrowLeft) {
            if (index > 0) {
                event.preventDefault();
                this._inputElements[index - 1].focus();
                this._inputElements[index - 1].select();
            }
            return;
        };
        if (event.key == Key.ArrowRight) {
            if (index + 1 < this._inputElements.length) {
                event.preventDefault();
                this._inputElements[index + 1].focus();
                this._inputElements[index + 1].select();
            }
            return;
        }
    }

    private async updateInput(): Promise<void> {
        const inputValue: string = Array.from(this._inputElements).reduce((otp, input) => {
            otp += (input.value.length) ? input.value : '';
            return otp;
        }, '');
        this._inputElement.value = inputValue;
    }

    public get value(): string {
        return this._inputElement.value;
    }

    public set value(value: string) {
        this._inputElement.value = value;
        this.onInput();
    }

    public get digits(): number {
        return this._digits;
    }
}